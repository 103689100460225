<template>
  <div class="UserStatisticsResumeMenuAdmin" v-if="$isAdmin">
    <Spacer num="1" bodybg border-bottom />
    <LargeButton
      :label="$locale['set_commission_percent']"
      icon="percent"
      @click="view({ user, title: $locale['set_commission_percent'], component: () => import('./SetCommissionView.vue') })"
    />
    <Spacer num="1" bodybg border-bottom />
  </div>
</template>

<script>
export default {
  props: ["user", "modal", "statistics"],
};
</script>
